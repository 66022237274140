<template>
    <div class="main">
        <el-scrollbar max-height="750px">
            <el-empty v-if="parents.length <= 0" description="暂无家长信息"></el-empty>
            <div v-else v-for="(item, index) in parents" :key="index">
                <el-descriptions :title="`家长` + (index + 1)" :column="2" :size="size" border>
                    <template #extra>
                        <el-button type="primary" icon="el-icon-edit" size="small" @click="editStudentParent(item.Id)">编辑</el-button>
                        <el-popconfirm confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red" title="确定删除吗？" @confirm="parentDelete(item.Id)">
                            <template #reference>
                                <el-button type="danger" icon="el-icon-delete" size="small">删除</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                    <el-descriptions-item>
                        <template #label>
                            <i class="el-icon-user"></i>
                            姓名
                        </template>
                        {{ item.UserName }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <i class="el-icon-connection"></i>
                            关系
                        </template>
                        <el-tag size="small">{{ item.RelationStr }}</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <i class="el-icon-mobile-phone"></i>
                            电话
                        </template>
                        {{ item.UserPhoneAccount }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <i class="el-icon-tickets"></i>
                            身份证号
                        </template>
                        {{ item.UserIDCard }}
                    </el-descriptions-item>
                </el-descriptions>
                <el-divider />
            </div>
        </el-scrollbar>
        <el-divider>
            <el-button type="primary" icon="el-icon-plus" @click="addStudentParent">添加家长</el-button>
        </el-divider>
        <el-dialog :title="studentParentDialogTitle" v-model="studentParentDialogVisible" width="30%" center>
            <el-form :model="parentForm" ref="parentForm" label-width="100px">
                <div v-for="(item, index) in parentFormConfig" :key="index">
                    <el-form-item :label="item.label" v-if="item.isInput != false" :rules="item.rules" :prop="item.attr">
                        <el-select v-if="item.type == 'enum'" v-model="parentForm[item.attr]" :placeholder="item.placeholder" filterable>
                            <el-option v-for="ite in item.dic" :key="ite.value" :label="ite.title" :value="ite.value">
                            </el-option>
                        </el-select>
                        <el-input v-else v-model="parentForm[item.attr]" :placeholder="item.placeholder" :disabled="disabled"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="studentParentDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="saveStudentParent('parentForm')">保存</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "studentParent",
    props: {
        stuid: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            parents: [],
            studentParentDialogTitle: "",
            studentParentDialogVisible: false,
            disabled: false,
            parentForm: {
                Id: 0,
                StudentId: 0,
                UserName: "",
                Relation: 0,
                UserPhoneAccount: "",
                UserIDCard: "",
            },
            parentFormConfig: [
                {
                    label: "家长姓名",
                    attr: "UserName",
                    rules: [{ max: 10, message: "长度不大于10!" }],
                    type: "text",
                },
                {
                    label: "与学生关系",
                    attr: "Relation",
                    placeholder: "请选择与学生关系",
                    rules: [
                        {
                            required: true,
                            message: "请选择与学生关系!",
                            trigger: "change",
                        },
                    ],
                    type: "enum",
                    dic: [
                        { value: 0, title: "父母" },
                        { value: 1, title: "祖父母" },
                        { value: 2, title: "兄弟姐妹" },
                        { value: 3, title: "其他" },
                    ],
                },
                {
                    label: "电话号码",
                    attr: "UserPhoneAccount",
                    placeholder: "请输入电话号码",
                    type: "text",
                    rules: [
                        {
                            required: true,
                            trigger: "blur",
                            message: "请输入电话号码!",
                        },
                        {
                            pattern: /^1[3456789]\d{9}$/,
                            message: "格式验证失败,请检查电话号码是否正确!",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "身份证号",
                    attr: "UserIDCard",
                    placeholder: "请输入身份证号",
                    type: "text",
                    rules: [
                        {
                            required: false,
                            trigger: "blur",
                            message: "请输入身份证号!",
                        },
                        {
                            pattern:
                                /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                            message: "格式验证失败,请检查身份证号是否正确!",
                            trigger: "blur",
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        this.initStudentParents();
    },
    methods: {
        initStudentParents() {
            var that = this;
            if (that.$props.stuid != 0) {
                that.$API.StudentParent.GetStudentParentsByStuId.get(
                    that.$props.stuid
                ).then((res) => {
                    that.parents = res;
                });
            }
        },
        parentDelete(id) {
            var that = this;
            that.$API.StudentParent.FakeDelete.delete([id]).then((res) => {
                if (res.Success) {
                    that.$message.success(res.Message);
                    that.initStudentParents();
                }
            });
        },
        addStudentParent() {
            var that = this;
            that.studentParentDialogVisible = true;
            that.disabled = false;
            that.studentParentDialogTitle = "添加家长";
            that.parentForm = {
                Id: 0,
                StudentId: that.$props.stuid,
                UserName: "",
                Relation: 0,
                UserPhoneAccount: "",
                UserIDCard: "",
            };
        },
        editStudentParent(id) {
            var that = this;
            var data = that.parents.filter(
                (it) => it.Id == id && it.StuId == that.$props.stuid
            );
            that.parentForm.Id = data[0].Id;
            that.parentForm.StudentId = that.$props.stuid;
            that.parentForm.UserName = data[0].UserName;
            that.parentForm.Relation = data[0].Relation;
            that.parentForm.UserPhoneAccount = data[0].UserPhoneAccount;
            that.parentForm.UserIDCard = data[0].UserIDCard;
            that.disabled = true;
            that.studentParentDialogVisible = true;
            that.studentParentDialogTitle = "编辑家长信息";
        },
        saveStudentParent(formName) {
            var that = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    that.$API.StudentParent.AddOrUpdate.post(
                        that.parentForm
                    ).then((res) => {
                        that.studentParentDialogVisible = false;
                        if (res.Success) {
                            that.$message.success(res.Message);
                            that.initStudentParents();
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style>
.main {
    margin: 20px;
}
</style>