<template>
  <div>
    <el-form label-position="left" label-width="80px">
      <el-form-item label="服务项目">
        <el-select v-model="checkItemId" placeholder="请选择办理服务项目">
          <el-option
            v-for="item in checkServiceItemData"
            :key="item.Id"
            :label="item.ItemName"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="办理班级" v-show="cClaData && cClaData.length > 0">
        <el-select v-model="checkClasIds" multiple placeholder="请选择班级">
          <el-option
            v-for="item in cClaData"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="办理学生" v-show="cStuData && cStuData.length > 0">
        <el-select v-model="checkStuIds" multiple placeholder="请选择学生">
          <el-option
            v-for="item in cStuData"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="导入文件"
        v-show="!(cClaData && cClaData.length > 0) && !(cStuData && cStuData.length > 0)"
      >
        <el-row justify="center">
          <el-col :span="16" :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
            <el-upload
              :action="`${this.$CONFIG.API_URL}/File/UploadImportStudentExcelFile`"
              :on-success="uploadSuccess"
              :before-upload="beforeUploadFile"
              :headers="headers"
              :on-change="handleChange"
              :file-list="fileList"
              :before-remove="removeFile"
              drag
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
                <div class="el-upload__tip">只能上传 excel 文件！</div>
              </div>
            </el-upload>
          </el-col>
        </el-row>
        <div
          class="el-form-item-msg"
          style="line-height: 20px"
          v-show="
            !(cClaData && cClaData.length > 0) && !(cStuData && cStuData.length > 0)
          "
        >
          注：<el-button
            type="text"
            @click="this.$API.Student.BatchProcesServiceImportTemplate.post()"
            >下载Excel模板</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <el-row justify="center" :gutter="20">
      <el-col :span="10">
        <el-button type="primary" @click="serviceHandling()">服务办理</el-button>
        <el-button type="danger" @click="cancelProcessing()">取消办理</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "batchProcesServiceItem",
  props: {
    cStuData: {
      type: Array,
      default: function () {
        return [{}];
      },
    },
    cClaData: {
      type: Array,
      default: function () {
        return [{}];
      },
    },
  },
  data() {
    return {
      importShow: false,
      checkItemId: "",
      checkServiceItemData: [],
      checkStuIds: [],
      checkClasIds: [],
      headers: {},
      fileData: {},
      fileList: [],
    };
  },
  mounted() {
    var that = this;
    that.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      let adminInfo = that.$TOOL.data.get("adminInfo");
      that.headers = {
        Authorization: "Bearer " + adminInfo.JwtToken,
      };
    });
    that.initCheckServiceItem();
    that.$props.cClaData.forEach((it) => {
      that.checkClasIds.push(it.Id);
    });
    that.$props.cStuData.forEach((it) => {
      that.checkStuIds.push(it.Id);
    });
  },
  methods: {
    initCheckServiceItem() {
      var that = this;
      that.$API.Student.GetServiceItem.get().then((res) => {
        that.checkServiceItemData = res;
      });
    },
    initImportShow() {},
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    removeFile() {
      this.fileList = [];
      this.fileData = {};
    },
    uploadSuccess(res) {
      if (res.Success && res.Code == 200) {
        this.fileData = res.Data;
      } else {
        console.log(res.Message);
      }
    },
    beforeUploadFile(file) {
      const fileType = file.type;
      const isExcel = !(
        fileType != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        fileType != "application/vnd.ms-excel"
      );
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isExcel) {
        this.$message.warning("上传Excel文件只能是.xls或者.xlsx 格式!");
      }
      if (!isLt10M) {
        this.$message.warning("上传Excel文件大小不能超过 10MB!");
      }
      return isExcel && isLt10M;
    },
    serviceHandling() {
      var that = this;
      if (!that.checkItemId || that.checkItemId == "") {
        that.$message.warning("请选择办理项目!");
        return;
      }
      var json = {
        ItemId: that.checkItemId,
        ClassIds: that.checkClasIds,
        StudentIds: that.checkStuIds,
        fileId: that.fileData.Id,
      };
      const loading = that.$loading({
        lock: true,
        text: "数据处理中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.$API.Student.BatchProcesServiceItem.post(json).then((res) => {
        loading.close();
        if (res.Url && res.Url != "") {
          that.$alert(res.Message, "提示", {
            confirmButtonText: "确定下载",
            callback: () => {
              window.location.href = res.Url;
            },
          });
        } else if (res.Success) {
          that.$message.success(res.Message);
        } else {
          that.$message.error(res.Message);
        }
        that.$emit("operationSuccess");
      });
    },
    cancelProcessing() {
      var that = this;
      if (!that.checkItemId || that.checkItemId == "") {
        that.$message.warning("请选择办理项目!");
        return;
      }
      var json = {
        ItemId: that.checkItemId,
        ClassIds: that.checkClasIds,
        StudentIds: that.checkStuIds,
        fileId: that.fileData.Id,
      };
      const loading = that.$loading({
        lock: true,
        text: "数据处理中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.$API.Student.BatchCancelProcesServiceItem.post(json).then((res) => {
        loading.close();
        if (res.Url && res.Url != "") {
          that.$alert(res.Message, "提示", {
            confirmButtonText: "确定下载",
            callback: () => {
              window.location.href = res.Url;
            },
          });
        } else if (res.Success) {
          that.$message.success(res.Message);
        } else {
          that.$message.error(res.Message);
        }
        that.$emit("operationSuccess");
      });
    },
  },
};
</script>

<style></style>
