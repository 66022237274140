<template>
	<div class="page">
		<el-main>
			<el-container>
				<el-row>
					<el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form :inline="true">
							<el-form-item>
								<el-select v-model="checkClasses" multiple filterable collapse-tags placeholder="请选择班级" style="width: 220px" @change="SearchPage()" size="medium">
									<el-option v-for="(item, i) in checkClassesData" :key="i" :label="item.ClassName" :value="item.Id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-select v-model="checkStudentType" filterable collapse-tags placeholder="请选择学生类型" style="width: 180px" @change="SearchPage()" size="medium">
									<el-option v-for="(item, i) in checkStudentTypeData" :key="i" :label="item.title" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-input v-model="keyword" placeholder="请输入关键字查询" class="input-with-select" style="width: 300px; margin-right: 10px" size="medium">
								<template #prepend>
									<el-select v-model="checkInput" multiple collapse-tags placeholder="请选择" style="width: 120px">
										<el-option v-for="(item, i) in dataTableSearch" :key="i" :label="item.label" :value="item.attr"></el-option>
									</el-select>
								</template>
							</el-input>
							<slot name="searchSlot"></slot>
							<el-form-item>
								<el-button type="primary" icon="el-icon-search" size="medium" @click="SearchPage()">查询</el-button>
								<el-button type="success" v-has="'Add'" icon="el-icon-edit" @click="studentAdd()" size="medium">新增</el-button>
								<el-button type="danger" v-has="'FakeDelete'" icon="el-icon-delete" size="medium" @click="DeleteStudents()">删除</el-button>
								<el-dropdown>
									<el-button type="primary" size="medium" icon="el-icon-upload" v-has="'UniSyncStudentData,YqshSyncStudentData,TaideSyncStudentData'">数据同步 </el-button>
									<template #dropdown>
										<el-dropdown-menu>
											<el-dropdown-item icon="el-icon-upload" v-has="'UniSyncStudentData'" @click="uniSyncData()">宇泛数据同步</el-dropdown-item>
											<el-dropdown-item icon="el-icon-upload" v-has="'YqshSyncStudentData'" @click="yqshSyncData()">一起生活同步</el-dropdown-item>
											<el-dropdown-item icon="el-icon-upload" v-has="'TaideSyncStudentData'" @click="taideSyncData()">泰德数据同步</el-dropdown-item>
										</el-dropdown-menu>
									</template>
								</el-dropdown>
								<el-dropdown style="margin-left: 10px !important">
									<el-button type="primary" size="medium" v-has="'StudentInfoImporter,ExportExcel,ImportStudentPhoto,ExportStudentPhoto,GetServiceItem,BatchCardInfoImporter'">
										<i class="el-icon-arrow-down el-icon-menu"></i>批量操作
									</el-button>
									<template #dropdown>
										<el-dropdown-menu>
											<el-dropdown-item v-has="'StudentInfoImporter'" icon="el-icon-upload2" @click="importStudentDialogVisible = true">学生信息导入</el-dropdown-item>
											<el-dropdown-item v-has="'ExportExcel'" icon="el-icon-download" @click="ExportExcel()">学生信息导出</el-dropdown-item>
											<el-dropdown-item v-has="'ImportStudentPhoto'" icon="el-icon-upload2" @click="importStudentPhotoDialogVisible = true">学生照片导入</el-dropdown-item>
											<el-dropdown-item v-has="'ExportStudentPhoto'" icon="el-icon-download" @click="ExportStuPhoto()">学生照片导出</el-dropdown-item>
											<el-dropdown-item v-has="'GetServiceItem'" icon="el-icon-paperclip" @click="BatchProcesServiceItem()">服务项目办理</el-dropdown-item>
											<el-dropdown-item v-has="'BatchCardInfoImporter'" icon="el-icon-upload2" @click="importCardInfoDialogVisible = true">校园卡信息导入</el-dropdown-item>
										</el-dropdown-menu>
									</template>
								</el-dropdown>
							</el-form-item>
							<el-form-item>
								<el-popover placement="bottom" :width="400" trigger="click">
									<template #reference>
										<el-button size="medium"><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button>
									</template>
									<el-checkbox-group v-model="dataTableSearchList">
										<div v-for="(item, i) in dataTableList" :key="i">
											<el-checkbox v-if="item.visible != false" :label="item" checked style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)"> {{ item.label }}</el-checkbox>
											<el-checkbox v-else :label="item" style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)"> {{ item.label }}</el-checkbox>
										</div>
									</el-checkbox-group>
									<el-button size="small" type="text" @click="filterFunHandle('allchecked')">全选</el-button>
									<el-button size="small" type="text" @click="filterFunHandle('cancel')">取消全选</el-button>
								</el-popover>
							</el-form-item>
						</el-form>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-table size="small" border ref="multipleTable" :data="dataTable" style="width: 99%" max-height="700" :height="tableHeight" :fit="true" @selection-change="handleSelectionChange">
							<el-table-column type="selection" fixed="left" header-align="center" align="center" width="50"></el-table-column>
							<el-table-column label="操作" fixed="right" header-align="center" align="center" width="300">
								<template #default="scope">
									<el-button size="mini" v-has="'GetStudentParentsByStuId'" @click="stuParent(scope.row.Id, scope.row.StudentName)">关联家长</el-button>
									<el-button size="mini" v-has="'Update'" @click="studentEdit(scope.row.Id)">编辑</el-button>
									<el-popconfirm confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red" title="确定删除吗？" @confirm="studentDelete(scope.row.Id)">
										<template #reference>
											<el-button type="danger" v-has="'FakeDelete'" size="mini">删除</el-button>
										</template>
									</el-popconfirm>
								</template>
							</el-table-column>
							<el-table-column v-for="(col, index) in dataTableSearchList" :key="index" sortable header-align="center" align="center" :prop="col.attr" :label="col.label">
								<template #default="scope">
									<el-image v-if="col.type == 'img'" :src="scope.row[col.attr]" :preview-src-list="[scope.row[col.attr]]" fit="fill" style="width: 40px; height: 40px"></el-image>
									<el-tag size="medium" v-else-if="col.type == 'enum'">
										{{ col.formatters(scope.row[col.attr]) }}
									</el-tag>
									<div v-else-if="col.type == 'custom'">
										<el-tag size="medium" v-if="scope.row[col.attr] == '【正常使用】'">
											{{ scope.row[col.attr] }}
										</el-tag>
										<el-tag size="medium" type="danger" v-else-if="scope.row[col.attr] == '【未办理】'">
											{{ scope.row[col.attr] }}
										</el-tag>
										<el-tag size="medium" type="warning" v-else>
											{{ scope.row[col.attr] }}
										</el-tag>
									</div>
									<label v-else>{{ scope.row[col.attr] }}</label>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<paging :currentPage="currentPage" :pagesize="pageSize" :total="total" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange" :pagesizes="pageSizes" />
					</el-col>
				</el-row>
			</el-container>
		</el-main>
		<el-dialog v-model="dialogVisible" :title="dialogTitle" width="30%" :before-close="handleClose" destroy-on-close center>
			<saveStudent @studentSaveSuccess="operationSuccess" :stuid="stuid" />
		</el-dialog>

		<el-dialog title="学生信息导入" v-model="importStudentDialogVisible" width="30%" :before-close="handleClose" destroy-on-close center>
			<importStudent @importStudentSuccess="operationSuccess" />
		</el-dialog>

		<el-dialog title="学生照片导入" v-model="importStudentPhotoDialogVisible" width="30%" :before-close="handleClose" destroy-on-close center>
			<importStudentPhoto @operationSuccess="operationSuccess" />
		</el-dialog>

		<el-dialog title="服务项目办理" v-model="batchPSIDialogVisible" width="30%" :before-close="handleClose" destroy-on-close center>
			<batchProcesServiceItem @operationSuccess="operationSuccess" :cStuData="cstuData" :cClaData="cClaData" />
		</el-dialog>

		<el-drawer v-model="stuParentVisible" direction="rtl" size="30%" :before-close="handleClose" destroy-on-close>
			<slot name="title"
				><el-divider
					><h2>
						<span class="drawerTitle">{{ stuParentDrawerTitle }}</span
						>家长信息
					</h2></el-divider
				></slot
			>
			<studentParent @operationSuccess="operationSuccess" :stuid="stuid" />
		</el-drawer>

		<el-dialog title="配置选择" v-model="uniDataSyncDialogVisible" width="20%" :before-close="handleClose" destroy-on-close center>
			<center>
				<el-select v-model="checkUniAppId" placeholder="请选择同步配置" filterable>
					<el-option v-for="ite in checkUniConfigData" :key="ite.AppID" :label="ite.ConfigName" :value="ite.AppID"> </el-option>
				</el-select>
			</center>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="uniDataSyncDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="uniDataSyncSave()">确 定</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog title="配置选择" v-model="yqshDataSyncDialogVisible" width="20%" :before-close="handleClose" destroy-on-close center>
			<center>
				<el-select v-model="checkYqshConfigId" placeholder="请选择同步配置" filterable>
					<el-option v-for="ite in checkYqshConfigData" :key="ite.Id" :label="ite.TeamName" :value="ite.Id"> </el-option>
				</el-select>
				<div class="el-form-item-msg" style="line-height: 10px;margin-top:10px;">
					注：同步人员必须设置身份证号信息!
				</div>
			</center>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="yqshDataSyncDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="yqshDataSyncSave()">确 定</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog title="配置选择" v-model="taideDataSyncDialogVisible" width="20%" :before-close="handleClose" destroy-on-close center>
			<center>
				<el-select v-model="checkTaideConfigId" placeholder="请选择同步配置" filterable>
					<el-option v-for="ite in checkTaideConfigData" :key="ite.Id" :label="ite.ConfigName" :value="ite.Id"> </el-option>
				</el-select>
				<div class="el-form-item-msg" style="line-height: 10px;margin-top:10px;">
					注：同步人员必须设置校园卡信息!
				</div>
			</center>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="taideDataSyncDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="taideDataSyncSave()">确 定</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog title="学生校园卡信息导入" v-model="importCardInfoDialogVisible" width="30%" :before-close="handleClose" destroy-on-close center>
			<importCardInfo @operationSuccess="operationSuccess" />
		</el-dialog>
	</div>
</template>
<script>
import "@element-plus/icons";
import saveStudent from "./saveStudent";
import importStudent from "./importStudent";
import importStudentPhoto from "./importStudentPhoto";
import studentParent from "./studentParent";
import batchProcesServiceItem from "./batchProcesServiceItem";
import importCardInfo from "./importCardInfo.vue";
export default {
	name: "Student",
	components: {
		saveStudent,
		importStudent,
		importStudentPhoto,
		studentParent,
		batchProcesServiceItem,
		importCardInfo,
	},
	data() {
		return {
			checkClasses: "",
			checkClassesData: [],
			uniDataSyncDialogVisible: false, //学生宇泛数据同步弹出框
			checkUniAppId: "",
			checkUniConfigData: [],
			yqshDataSyncDialogVisible: false, //学生一起生活数据同步弹出框
			checkYqshConfigId: "",
			checkYqshConfigData: [],
			taideDataSyncDialogVisible: false, //学生泰德数据同步弹出框
			checkTaideConfigId: "",
			checkTaideConfigData: [],
			checkStudentType: 3,
			checkStudentTypeData: [
				{ title: "请选择学生类型", value: 3 },
				{ title: "走读生", value: 0 },
				{ title: "半走读", value: 1 },
				{ title: "住校生", value: 2 },
			],
			keyword: "", //搜索输入关键字
			checkInput: "", //选择所需要查询的内容
			dataConfig: [
				{
					label: "Id",
					attr: "Id",
					isInput: false,
					isDisplay: false,
				},
				{ label: "班级", attr: "ClassName" },
				{
					label: "姓名",
					attr: "StudentName",
					isSearch: true,
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入姓名",
						},
					],
				},
				{
					label: "学生类型",
					attr: "StudentType",
					placeholder: "请选择学生类型",
					rules: [
						{
							required: true,
							message: "请选择学生类型!",
							trigger: "change",
						},
					],
					type: "enum",
					dic: [
						{ value: 0, title: "走读生" },
						{ value: 1, title: "半走读" },
						{ value: 2, title: "住校生" },
					],
					formatters: function(val) {
						var result;
						switch (val) {
							case 0:
								result = "走读生";
								break;
							case 1:
								result = "半走读";
								break;
							case 2:
								result = "住校生";
								break;
							default:
								result = "未知";
								break;
						}
						return result;
					},
				},
				{ label: "照片", attr: "StudentPhotoUrl", type: "img" },
				{
					label: "性别",
					attr: "Sex",
					type: "enum",
					dic: [
						{ value: 1, title: "男" },
						{ value: 2, title: "女" },
					],
					formatters: function(val) {
						var result;
						switch (val) {
							case 1:
								result = "男";
								break;
							case 2:
								result = "女";
								break;
							default:
								result = "未知";
								break;
						}
						return result;
					},
				},
				{ label: "身份证号码", attr: "StudentIDCard", isSearch: true },
				{ label: "手机号码", attr: "StudentPhone", isSearch: true },
				{
					label: "生日",
					attr: "StudentBirthday",
					type: "date",
					visible: false,
				},
				{
					label: "学籍号",
					attr: "StudentNumber",
					isInput: false,
					visible: false,
				},
				{
					label: "宿舍编号",
					attr: "DormitoryNumber",
					isInput: false,
					visible: false,
				},
				{
					label: "校园卡(张)",
					attr: "CardInfoCount",
					isInput: false,
					visible: false,
				},
				{
					label: "绑定码",
					attr: "BindCode",
					isInput: false,
					visible: false,
				},
				{
					label: "家庭住址",
					attr: "FamilyAddress",
					isInput: false,
					visible: false,
				},
				{
					label: "户籍地址",
					attr: "ResidenceAddress",
					isInput: false,
					visible: false,
				},
				{
					label: "精准扶贫",
					attr: "IsHelpPoor",
					isInput: false,
					visible: false,
					type: "enum",
					formatters: function(val) {
						return val ? "是" : "不是";
					},
				},
				{
					label: "留守儿童",
					attr: "IsLeftBehindChildren",
					isInput: false,
					visible: false,
					type: "enum",
					formatters: function(val) {
						return val ? "是" : "不是";
					},
				},
				{
					label: "服务办理信息",
					attr: "ServiceProcesTag",
					isInput: false,
					visible: false,
					type: "custom",
				},
				{
					label: "备注",
					attr: "RemarkInfo",
					isInput: false,
					visible: false,
				},
				{
					label: "学习阶段",
					attr: "ClassTypeText",
					isInput: false,
					visible: false,
				},
				{
					label: "年级",
					attr: "ClassGrade",
					isInput: false,
					visible: false,
				},
				{
					label: "创建时间",
					attr: "CreatedTime",
					isInput: false,
					visible: false,
				},
				{
					label: "创建者",
					attr: "CreatedUser",
					isInput: false,
					visible: false,
				},
				{
					label: "更新时间",
					attr: "UpdatedTime",
					isInput: false,
					visible: false,
				},
				{
					label: "更新者",
					attr: "UpdatedUser",
					isInput: false,
					visible: false,
				},
			],
			dataTableSearch: [], //搜索信息
			dataTableList: [], //显示列信息
			dataTableSearchList: [], //列筛选信息
			dataTableFrom: [], //新增修改信息
			dataTable: [], //显示数据
			searchStartTime: "", //搜索开始时间
			searchEndTime: "", //搜索结束时间
			dialogTitle: "", //弹出框标题
			dialogVisible: false, //是否显示学生信息创建或编辑弹出框
			importStudentDialogVisible: false, //是否显示学生信息导入弹出框
			importStudentPhotoDialogVisible: false, //是否显示学生照片导入弹出框
			importCardInfoDialogVisible: false,
			stuParentVisible: false, //是否显示家长管理
			stuParentDrawerTitle: "", //家长管理标题
			batchPSIDialogVisible: false, //服务办理模态框
			cstuData: [],
			cClaData: [],
			form: {}, //新增修改数据
			multipleSelection: [], //选中数据
			total: 0, //数据总条数
			pageSize: 10, //当前显示数据数量
			pageSizes: [10, 20, 50, 100],
			currentPage: 1, //分页当前页
			headers: {}, //图片上传时，headers配置
			tableHeight: 200, //table高度
			stuid: 0,
		};
	},
	mounted() {
		const that = this;
		that.$nextTick(function() {
			// 仅在整个视图都被渲染之后才会运行的代码
			//设置图片上传header 参数
			let adminInfo = that.$TOOL.data.get("adminInfo");
			that.headers = {
				Authorization: "Bearer " + adminInfo.JwtToken,
			};
			//设置table位置
			let heightStyle = window.innerHeight - 230;
			that.tableHeight = heightStyle;

			//窗口大小改变时，table 高度设置
			window.onresize = () => {
				return (() => {
					let heightStyle = window.innerHeight - 230;
					that.tableHeight = heightStyle;
				})();
			};
		});
		that.initCheckClassList();
		that.SearchPage();
		that.dataTableSearch = that.dataConfig.filter((it) => it.isSearch == true); //可搜索字段
		that.dataTableList = that.dataConfig.filter((it) => it.isDisplay != false); //可显示列
	},
	methods: {
		//初始化班级选择数据
		initCheckClassList() {
			var that = this;
			that.$API.Classes.GetClassesDataList.get().then((res) => {
				that.checkClassesData = res;
			});
		},
		//页面搜索查询
		SearchPage() {
			let that = this;
			let json = {
				searchValue: "",
				pageNo: that.currentPage,
				pageSize: that.pageSize,
				searchBeginTime: that.searchStartTime,
				searchEndTime: that.searchEndTime,
				sortField: "",
				sortOrder: "",
				descStr: "",
				ClassIds: that.checkClasses,
				StuType: that.checkStudentType,
			};
			if (that.checkInput.length > 0 && that.keyword != "") {
				var searchParameters = [],
					input = that.keyword;
				for (let index = 0; index < that.checkInput.length; index++) {
					const element = that.checkInput[index];
					var searchParametersItem = {
						field: element,
						op: 8,
						value: input,
						orGroup: "",
					};
					searchParameters.push(searchParametersItem);
				}
				json.searchParameters = searchParameters;
			}
			console.log(json);
			that.$API.Student.QueryByPage.post(json).then((res) => {
				if (res) {
					that.total = res.TotalRows;
					that.pagesize = res.PageSize;
					that.currentPage = res.PageNo;
					that.dataTable = res.Rows;
				}
			});
		},
		handleSizeChange(val) {
			this.pageSize = val;
			this.SearchPage();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.SearchPage();
		},
		studentAdd() {
			this.stuid = 0;
			this.dialogTitle = "新增学生信息";
			this.dialogVisible = true;
		},
		studentEdit(id) {
			this.stuid = id;
			this.dialogTitle = "修改学生信息";
			this.dialogVisible = true;
		},
		handleClose(done) {
			this.$confirm("确认关闭？")
				.then(() => {
					done();
				})
				.catch(() => {});
		},
		operationSuccess() {
			//操作成功后，关闭弹出框，刷新数据显示
			this.dialogVisible = false;
			this.importStudentDialogVisible = false;
			this.importStudentPhotoDialogVisible = false;
			this.stuParentVisible = false;
			this.stuParentDrawerTitle = "";
			this.batchPSIDialogVisible = false;
			this.importCardInfoDialogVisible = false;
			this.stuid = 0;
			this.SearchPage();
		},
		studentDelete(id) {
			var that = this;
			that.$API.Student.FakeDelete.delete([id]).then((res) => {
				if (res.Success) {
					that.$message({ message: res.Message, type: "success" });
					that.SearchPage();
				}
			});
		},
		DeleteStudents() {
			var that = this;
			if (!that.multipleSelection || that.multipleSelection.length <= 0) {
				that.$message.warning("请选择需要删除的学生！");
				return;
			}
			var ids = new Array();
			var msgStr = "";
			that.multipleSelection.forEach((it) => {
				ids.push(it.Id);
				msgStr += `【${it.StudentName}】`;
			});
			that
				.$confirm(`确认删除${msgStr}等${that.multipleSelection.length}人的信息吗？`, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
					center: true,
				})
				.then(() => {
					that.$API.Student.FakeDelete.delete(ids).then((res) => {
						if (res.Success) {
							that.$message.success(res.Message);
							that.SearchPage();
						}
					});
				})
				.catch(() => {});
		},
		BatchProcesServiceItem() {
			var that = this;
			that.cstuData = [];
			that.cClaData = [];
			var msgStr = "";
			if (that.multipleSelection && that.multipleSelection.length > 0) {
				that.multipleSelection.forEach((it) => {
					that.cstuData.push({
						Id: it.Id,
						Name: it.StudentName,
					});
					msgStr += `【${it.StudentName}】`;
				});
				that
					.$confirm(`确认为学生${msgStr}等${that.multipleSelection.length}人进行服务项目办理吗？`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
						center: true,
					})
					.then(() => {
						that.batchPSIDialogVisible = true;
					})
					.catch(() => {});
			} else if (that.checkClasses && that.checkClasses.length > 0) {
				that.checkClasses.forEach((it) => {
					var data = that.checkClassesData.filter((i) => i.Id == it)[0];
					if (data) {
						that.cClaData.push({ Id: it, Name: data.ClassName });
						msgStr += `【${data.ClassName}】`;
					}
				});
				that
					.$confirm(`确认为班级${msgStr}等${that.checkClasses.length}个班进行服务项目办理吗？`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
						center: true,
					})
					.then(() => {
						that.batchPSIDialogVisible = true;
					})
					.catch(() => {});
			} else {
				that
					.$confirm(`确认为全校或导入Excel文件进行服务项目办理吗？`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
						center: true,
					})
					.then(() => {
						that.batchPSIDialogVisible = true;
					})
					.catch(() => {});
			}
		},
		handleSelectionChange(val) {
			//console.info(val);
			this.multipleSelection = val;
		},
		// 列筛选
		filterFunHandle(type, currentItem, index) {
			if (type === "allchecked") {
				// 全选
				this.dataTableSearchList = this.dataConfig.filter((it) => it.isDisplay != false);
			} else if (type === "cancel") {
				// 取消全选
				this.dataTableSearchList = []; // 复选框置为空，全部不选择
			}
		},
		//导出学生信息
		ExportExcel() {
			var that = this;
			var ids = new Array();
			that.multipleSelection.forEach((it) => {
				ids.push(it.Id);
			});
			let json = {
				searchValue: "",
				pageNo: that.currentPage,
				pageSize: that.pageSize,
				searchBeginTime: that.searchStartTime,
				searchEndTime: that.searchEndTime,
				sortField: "",
				sortOrder: "",
				descStr: "",
				ClassIds: that.checkClasses,
				StuType: that.checkStudentType,
				StuIds: ids,
			};
			if (that.checkInput.length > 0 && that.keyword != "") {
				var searchParameters = [],
					input = that.keyword;
				for (let index = 0; index < that.checkInput.length; index++) {
					const element = that.checkInput[index];
					var searchParametersItem = {
						field: element,
						op: 8,
						value: input,
						orGroup: "",
					};
					searchParameters.push(searchParametersItem);
				}
				json.searchParameters = searchParameters;
			}
			that.$API.Student.ExportExcel.post(json);
		},

		ExportStuPhoto() {
			var that = this;
			var ids = new Array();
			that.multipleSelection.forEach((it) => {
				ids.push(it.Id);
			});
			let json = {
				searchValue: "",
				pageNo: that.currentPage,
				pageSize: that.pageSize,
				searchBeginTime: that.searchStartTime,
				searchEndTime: that.searchEndTime,
				sortField: "",
				sortOrder: "",
				descStr: "",
				ClassIds: that.checkClasses,
				StuType: that.checkStudentType,
				StuIds: ids,
			};
			const loading = that.$loading({
				lock: true,
				text: "数据处理中",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			that.$API.Student.ExportStudentPhoto.post(json).then((res) => {
				loading.close();
				console.log(res);
				if (res.Url && res.Url != "") {
					window.location.href = res.Url;
				} else if (res.Success) {
					that.$message.success(res.Message);
				} else {
					that.$message.error(res.Message);
				}
			});
		},

		//处理家长信息
		stuParent(stuId, stuName) {
			this.stuid = stuId;
			this.stuParentVisible = true;
			this.stuParentDrawerTitle = `${stuName}`;
		},
		// 下载文件
		download(data) {
			if (!data) {
				return;
			}
			let url = window.URL.createObjectURL(new Blob([data]));
			let link = document.createElement("a");
			link.style.display = "none";
			link.href = url;
			var date = new Date();
			//date.Format('yyyyMMddHHmmss')
			link.setAttribute("download", date.Format("yyyyMMddHHmmss") + ".xlsx");
			document.body.appendChild(link);
			link.click();
		},
		uniSyncData() {
			var that = this;
			that.checkUniAppId = "";
			that.checkUniConfigData = [];
			that.$API.UfaceConfig.GetUfaceConfigList.get().then((res) => {
				if (res && res.length > 0) {
					that.checkUniConfigData = res;
					that.uniDataSyncDialogVisible = true;
				} else {
					that.$message.error("未获取到数据同步配置!");
				}
			});
		},
		uniDataSyncSave() {
			var that = this;
			var configData = that.checkUniConfigData.filter((i) => i.AppID == that.checkUniAppId)[0];
			if (that.checkUniAppId == "" || !configData) {
				that.$message.warning("请选择同步配置!");
				return;
			}
			that.cstuIds = [];
			var msgStr = "";
			var msgTxt = "";
			if (that.multipleSelection && that.multipleSelection.length > 0) {
				that.multipleSelection.forEach((it) => {
					that.cstuIds.push(it.Id);
					msgStr += `【${it.StudentName}】`;
				});
				msgTxt = `确认同步学生${msgStr}等${that.multipleSelection.length}人信息`;
			} else if (that.checkClasses && that.checkClasses.length > 0) {
				that.checkClasses.forEach((it) => {
					var data = that.checkClassesData.filter((i) => i.Id == it)[0];
					if (data) {
						msgStr += `【${data.ClassName}】`;
					}
				});
				msgTxt = `确认同步班级${msgStr}等${that.checkClasses.length}个班的学生信息`;
			} else {
				msgTxt = `确认同步全校学生信息`;
			}
			that
				.$confirm(msgTxt + `至宇泛配置【${configData.ConfigName}】吗？`, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
					center: true,
				})
				.then(() => {
					var json = {
						AppId: that.checkUniAppId,
						ClassIds: that.checkClasses,
						StudentIds: that.cstuIds,
					};
					that.$message.success("开始同步");
					that.uniDataSyncDialogVisible = false;
					// const loading = that.$loading({
					//   lock: true,
					//   text: "数据处理中",
					//   spinner: "el-icon-loading",
					//   background: "rgba(0, 0, 0, 0.7)",
					// });
					that.$API.Student.UniSyncStudentData.post(json).then((res) => {
						// loading.close();
						that.uniDataSyncDialogVisible = false;
						if (res.Success) {
							that.$message.success(res.Message);
						} else {
							that.$message.error(res.Message);
						}
					});
				})
				.catch(() => {});
		},
		yqshSyncData() {
			var that = this;
			that.checkYqshConfigId = "";
			that.checkYqshConfigData = [];
			that.$API.YqshConfig.GetYqshConfigList.get().then((res) => {
				if (res && res.length > 0) {
					that.checkYqshConfigData = res;
					that.yqshDataSyncDialogVisible = true;
				} else {
					that.$message.error("未获取到数据同步配置!");
				}
			});
		},
		yqshDataSyncSave() {
			var that = this;
			var configData = that.checkYqshConfigData.filter((i) => i.Id == that.checkYqshConfigId)[0];
			if (that.checkYqshConfigId == "" || !configData) {
				that.$message.warning("请选择同步配置!");
				return;
			}
			that.cstuIds = [];
			var msgStr = "";
			var msgTxt = "";
			if (that.multipleSelection && that.multipleSelection.length > 0) {
				that.multipleSelection.forEach((it) => {
					that.cstuIds.push(it.Id);
					msgStr += `【${it.StudentName}】`;
				});
				msgTxt = `确认同步学生${msgStr}等${that.multipleSelection.length}人信息`;
			} else if (that.checkClasses && that.checkClasses.length > 0) {
				that.checkClasses.forEach((it) => {
					var data = that.checkClassesData.filter((i) => i.Id == it)[0];
					if (data) {
						msgStr += `【${data.ClassName}】`;
					}
				});
				msgTxt = `确认同步班级${msgStr}等${that.checkClasses.length}个班的学生信息`;
			} else {
				msgTxt = `确认同步全校学生信息`;
			}
			that
				.$confirm(msgTxt + `至一起生活配置【${configData.TeamName}】吗？`, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
					center: true,
				})
				.then(() => {
					var json = {
						ConfigId: that.checkYqshConfigId,
						ClassIds: that.checkClasses,
						StudentIds: that.cstuIds,
					};
					that.$message.success("开始同步");
					that.YqshDataSyncDialogVisible = false;
					// const loading = that.$loading({
					//   lock: true,
					//   text: "数据处理中",
					//   spinner: "el-icon-loading",
					//   background: "rgba(0, 0, 0, 0.7)",
					// });
					that.$API.Student.YqshSyncStudentData.post(json).then((res) => {
						// loading.close();
						that.yqshDataSyncDialogVisible = false;
						if (res.Success) {
							that.$message.success(res.Message);
						} else {
							that.$message.error(res.Message);
						}
					});
				})
				.catch(() => {});
		},
		taideSyncData() {
			var that = this;
			that.checkTaideConfigId = "";
			that.checkTaideConfigData = [];
			that.$API.TaideConfig.GetTaideConfigList.get().then((res) => {
				if (res && res.length > 0) {
					that.checkTaideConfigData = res;
					that.taideDataSyncDialogVisible = true;
				} else {
					that.$message.error("未获取到数据同步配置!");
				}
			});
		},
		taideDataSyncSave() {
			var that = this;
			var configData = that.checkTaideConfigData.filter((i) => i.Id == that.checkTaideConfigId)[0];
			if (that.checkTaideConfigId == "" || !configData) {
				that.$message.warning("请选择同步配置!");
				return;
			}
			that.cstuIds = [];
			var msgStr = "";
			var msgTxt = "";
			if (that.multipleSelection && that.multipleSelection.length > 0) {
				that.multipleSelection.forEach((it) => {
					that.cstuIds.push(it.Id);
					msgStr += `【${it.StudentName}】`;
				});
				msgTxt = `确认同步学生${msgStr}等${that.multipleSelection.length}人信息`;
			} else if (that.checkClasses && that.checkClasses.length > 0) {
				that.checkClasses.forEach((it) => {
					var data = that.checkClassesData.filter((i) => i.Id == it)[0];
					if (data) {
						msgStr += `【${data.ClassName}】`;
					}
				});
				msgTxt = `确认同步班级${msgStr}等${that.checkClasses.length}个班的学生信息`;
			} else {
				msgTxt = `确认同步全校学生信息`;
			}
			that
				.$confirm(msgTxt + `至泰德配置【${configData.ConfigName}】吗？`, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
					center: true,
				})
				.then(() => {
					var json = {
						ConfigId: that.checkTaideConfigId,
						ClassIds: that.checkClasses,
						StudentIds: that.cstuIds,
					};
					that.$message.success("开始同步");
					that.taideDataSyncDialogVisible = false;
					// const loading = that.$loading({
					//   lock: true,
					//   text: "数据处理中",
					//   spinner: "el-icon-loading",
					//   background: "rgba(0, 0, 0, 0.7)",
					// });
					that.$API.Student.TaideSyncStudentData.post(json).then((res) => {
						// loading.close();
						that.taideDataSyncDialogVisible = false;
						if (res.Success) {
							that.$message.success(res.Message);
						} else {
							that.$message.error(res.Message);
						}
					});
				})
				.catch(() => {});
		},
	},
};
</script>
<style scoped>
.page {
	margin: 5px;
	padding: 20px;
	min-height: 840px;
	width: 100%;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
	width: auto;
}
.drawerTitle {
	color: #409eff;
}
</style>
