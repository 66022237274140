<template>
  <div>
    <el-row justify="center">
      <el-col :span="16" :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
        <el-upload
          :action="`${this.$CONFIG.API_URL}/File/UploadImportStudentPhotoFile`"
          :on-success="uploadSuccess"
          :before-upload="beforeUploadFile"
          :headers="headers"
          :on-change="handleChange"
          :file-list="fileList"
          :before-remove="removeFile"
          drag
          accept="application/zip"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或<em>点击上传</em>
            <div class="el-upload__tip">
              只能上传zip文件！文件结构：班级名称目录》照片文件
            </div>
          </div>
        </el-upload>
      </el-col>
    </el-row>
    <el-row justify="center" :gutter="20">
      <el-col>
        <center>
          <el-button type="primary" @click="importStudent()">确定导入</el-button>
        </center>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "importStudentPhoto",
  data() {
    return {
      headers: {},
      fileData: {},
      fileList: [],
    };
  },
  mounted() {
    this.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      let adminInfo = this.$TOOL.data.get("adminInfo");
      this.headers = {
        Authorization: "Bearer " + adminInfo.JwtToken,
      };
    });
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    removeFile() {
      this.fileList = [];
      this.fileData = {};
    },
    uploadSuccess(res) {
      if (res.Success && res.Code == 200) {
        this.fileData = res.Data;
      } else {
        console.log(res.Message);
      }
    },
    beforeUploadFile(file) {
      const fileType = file.type;
      console.log(fileType);
      const isExcel = !(
        fileType !=
          "application/x-zip-compressed" &&
        fileType != "application/zip"
      );
      const isLt10M = file.size / 1024 / 1024 / 1024 < 1;

      if (!isExcel) {
        this.$message.warning("上传zip文件,仅支持.zip格式!");
      }
      if (!isLt10M) {
        this.$message.warning("上传zip文件大小不能超过 1GB!");
      }
      return isExcel && isLt10M;
    },
    importStudent() {
      var that = this;
      if (!that.fileData.Id) {
        that.$message.warning("请上传文件!");
        return;
      }
      const loading = that.$loading({
        lock: true,
        text: "数据处理中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.$API.Student.ImportStudentPhoto.post(that.fileData.Id).then((res) => {
        loading.close();
        console.log(res);
        if (res.Url && res.Url != "") {
          that.$alert(res.Message, "提示", {
            confirmButtonText: "确定下载",
            callback: () => {
              window.location.href = res.Url;
            },
          });
        } else if (res.Success) {
          that.$message.success(res.Message);
        } else {
          that.$message.error(res.Message);
        }
        that.$emit("operationSuccess");
      });
    },
  },
};
</script>

<style></style>
