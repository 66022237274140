<template>
  <div>
    <el-row justify="center">
      <el-col :span="16" :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
        <el-upload
          :action="`${this.$CONFIG.API_URL}/File/UploadImportStudentExcelFile`"
          :on-success="uploadSuccess"
          :before-upload="beforeUploadFile"
          :headers="headers"
          :on-change="handleChange"
          :file-list="fileList"
          :before-remove="removeFile"
          drag
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或<em>点击上传</em>
            <div class="el-upload__tip">只能上传 excel 文件！</div>
          </div>
        </el-upload>
      </el-col>
    </el-row>
    <el-row justify="center" :gutter="20">
      <el-col :span="10">
        <el-button @click="this.$API.Student.DownCardInfoImportTemplate.post()"
          >下载模板</el-button
        >
        <el-button type="primary" @click="importStudent()">确定导入</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "importCardInfo",
  data() {
    return {
      headers: {},
      fileData: {},
      fileList: [],
    };
  },
  mounted() {
    this.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      let adminInfo = this.$TOOL.data.get("adminInfo");
      this.headers = {
        Authorization: "Bearer " + adminInfo.JwtToken,
      };
    });
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    removeFile() {
      this.fileList = [];
      this.fileData = {};
    },
    uploadSuccess(res) {
      if (res.Success && res.Code == 200) {
        this.fileData = res.Data;
      } else {
        console.log(res.Message);
      }
    },
    beforeUploadFile(file) {
      const fileType = file.type;
      const isExcel = !(
        fileType !=
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        fileType != "application/vnd.ms-excel"
      );
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isExcel) {
        this.$message.warning("上传Excel文件只能是.xls或者.xlsx 格式!");
      }
      if (!isLt10M) {
        this.$message.warning("上传Excel文件大小不能超过 10MB!");
      }
      return isExcel && isLt10M;
    },
    importStudent() {
        var that = this;
        if(!that.fileData.Id){
            that.$message.warning("请上传文件!");
            return;
        }
        const loading = that.$loading({
          lock: true,
          text: '数据处理中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
       that.$API.Student.BatchCardInfoImporter.post(that.fileData.Id).then((res) => {
         loading.close();
         console.log(res);
         if(res.Url&&res.Url!=""){
             that.$alert(res.Message, '提示', {
               confirmButtonText: '确定下载',
               callback: () => {
                 window.location.href=res.Url;
               },
             });
         }else if(res.Success){
             that.$message.success(res.Message);
         }else{
             that.$message.error(res.Message);
         }
         that.$emit("operationSuccess");
       });
     },
  },
};
</script>

<style>
</style>